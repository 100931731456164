import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEOHeader from "../components/seo-header"
import { kebabCase } from "lodash"
import Img from "gatsby-image"
class CaseStudyTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    const slug = post.fields.slug
    return (
      <Layout location={this.props.location} title={siteTitle} type="blogPage">
        <SEOHeader
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          socialLocalURL={post.frontmatter.featuredImage.publicURL}
          pinterest="true"
          socialURL=""
        ></SEOHeader>
        <div className="conatiner-fluid">
          <div className="row aligner">
            <div className="col-md-7">
              <Img
                fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
                className="img-responsive"
                style={{ borderRadius: "10px" }}
                alt={post.frontmatter.title}
              />
            </div>
            <div className="col-md-5">
              <div className="caseTitleSection">
                <p style={{ fontSize: "12px" }}> <Link to="/case-studies" style={{ color: "#4267b2" }}> CASE STUDIES</Link> - {post.timeToRead} MINS</p>
                <h1 style={{ fontWeight: "bold" }}>{post.frontmatter.title}</h1>
                <h2>
                  {post.frontmatter.subTitle ? post.frontmatter.subTitle : ""}
                </h2>
                {/* <p className="blogDate">
                  <a
                    className="authorlinks"
                    href={post.frontmatter.authorURL}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {post.frontmatter.author}
                  </a>
                </p> */}
              </div></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12  ">
              <div
                className="caseStudiesContent"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
              
              <br /> <br />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-6"><ul className="socialShareButton">
                {" "}
                Share:
                <li>
                  <a
                    className="FacebookButton"
                    href={
                      "https://www.facebook.com/sharer.php?u=https://gofloaters.com/case-studies" +
                      slug
                    }
                    target="_blank"
                  >
                    <i className="fa fa-facebook"></i> Facebook
                  </a>
                </li>
                <li>
                  <a
                    className="TwitterButton"
                    href={
                      "https://twitter.com/intent/tweet?url=https://gofloaters.com/case-studies" +
                      slug +
                      "&text=" +
                      post.frontmatter.title +
                      "&hashtags=" +
                      post.frontmatter.category.toLowerCase().replace("-", "")
                    }
                    target="_blank"
                  >
                    <i className="fa fa-twitter"></i> Twitter
                  </a>
                </li>
                <li>
                  <a
                    className="linkedinButton"
                    href={
                      "https://www.linkedin.com/shareArticle?mini=true&url=https://gofloaters.com/case-studies" +
                      slug +
                      "&title=" +
                      post.frontmatter.title +
                      "&summary=" +
                      post.frontmatter.description +
                      "&source=GoFloaters"
                    }
                    target="_blank"
                  >
                    <i className="fa fa-linkedin"></i> LinkedIn
                  </a>
                </li>
              </ul>
              <br/> <br/></div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query CaseStudyPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        subTitle
        date 
        author
        tags
        category
        description
        authorURL
        featuredImage {
          relativeDirectory
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
    }
  }
`
